import axios from "axios";
import { useStore } from "vuex";

class NoticiasService {
  
  url = useStore().state.api_url;
  
  headers = {
    "Content-Type": "multipart/form-data", //"Content-Type": "application/json",
    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
  };

  constructor() {}

  async getData() {
    const res = axios({
      url: this.url + "/api/news",
      method: "GET",
    });

    return res;
  }

  async getId(id) {
    const res = axios({
      url: this.url + "/api/news/" + id,
      method: "GET",
      headers: this.headers,
    });

    return res;
  }
}

export default NoticiasService;
