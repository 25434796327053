import axios from "axios";
import { useStore } from "vuex";

class SolicitudesService {
  
  url = useStore().state.api_url;

  headers = {
    "Content-Type": "application/json", //"Content-Type": "application/json", "Content-Type": "multipart/form-data
    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
  };

  constructor() {}

  async getData() {
    const res = axios({
      url: this.url + "/api/User/Withdrawal",
      method: "GET",
      headers:this.headers,
    });

    return res;
  }

  async newSolicitud(datos) {
    const res = axios({
      url: this.url + "/api/User/Withdrawal",
      method: "POST",
      headers:this.headers,
      data: JSON.stringify(datos),
    });

    return res;
  }

  async getId(id) {
    const res = axios({
      url: this.url + "/api/User/Withdrawal/"+id,
      method: "GET",
      headers:this.headers,
    });

    return res;
  }


}

export default SolicitudesService;
