import axios from "axios";
import { useStore } from "vuex";

class RegistroService {
  
  url = useStore().state.api_url;

  headers = {
    "Content-Type": "application/json", //"Content-Type": "application/json", "Content-Type": "multipart/form-data
  };

  constructor() {}

  async register(datos) {
    const res = axios({
      url: this.url + "/api/User/register",
      method: "POST",
      headers:this.headers,
      data: JSON.stringify(datos),
    });

    return res;
  }


}

export default RegistroService;
