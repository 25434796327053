import axios from "axios";
import { useStore } from "vuex";

class CursosService {

  url = useStore().state.api_url;

  headers = {
    "Content-Type": "application/json", //"Content-Type": "application/json",
    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
  };

  constructor() {}

  async getData() {
    const res = axios({
      url: this.url + "/api/User/Dashboard",
      method: "GET",
      headers: this.headers,
    });

    return res;
  }

}

export default CursosService;
