<template>
  <main>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center"></div>
            </div>
            <div class="card-body">
              <h2 class="card-title">{{ titulo }}</h2>
              <h5>{{ formatDate(fechaCreacion) }}</h5>

              <div class="postImage">
                <img
                  :src="urlImage"
                />
              </div>
              <v-btn
                v-if="props['module'] === 'book'"
                prepend-icon="mdi-newspaper-variant-outline"
                :href="enlace"
                target="_blank"
              >
                <template v-slot:prepend>
                  <v-icon color="success"></v-icon>
                </template>
                Descargar libro
              </v-btn>
              <div v-html="markdownToHtml(cuerpo)" class="postBody"></div>
              <v-btn 
                v-if="props['module'] !== 'book'"
                prepend-icon="mdi-newspaper-variant-outline"
                :href="enlace"
                target="_blank"
              >
                <template v-slot:prepend>
                  <v-icon color="success"></v-icon>
                </template>
                LEER MÁS...
              </v-btn>
            </div>
          </div>
        </div>
        <!-- class="ms-auto btn" para los botones-->
      </div>
    </div>
  </main>
</template>

<script setup>
import { onBeforeMount, onMounted, onBeforeUnmount, onUpdated, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { marked } from "marked";

import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
//import ArgonButton from "@/components/ArgonButton.vue";

import NoticiasService from "../services/NoticiasService";
import LibrosService from "../services/LibrosService";
import CursosService from "../services/CursosService";

var service = null;

const body = document.getElementsByTagName("body")[0];

const props = defineProps(["module"]);

if (props.module === "news") service = new NoticiasService();
if (props.module === "book") service = new LibrosService();
if (props.module === "course") service = new CursosService();

const store = useStore();
const route = useRoute();

//:src="`https://apirpm.solopruebas.nl/resources/${imagen}`"
let imagen = ref("");
let titulo = ref("");
let cuerpo = ref("");
let fechaCreacion = ref("");
let descripcion = ref("");
let enlace = ref("");
let urlImage = ref("");

function formatDate(fecha) {
  if (fecha == null) {
    return "No Asignado";
  }

  var opciones = { year: "numeric", month: "short", day: "numeric" };
  var date = new Date(fecha)
    .toLocaleDateString("es", opciones)
    .replace(/ /g, "-")
    .replace(".", "")
    .replace(/-([a-z])/, function (x) {
      return "-" + x[1].toUpperCase();
    });
  return date;
}

function markdownToHtml(description) {
  return marked(description);
}

onUpdated(() => {
  //id.value = props.dataEdit.id;
  //console.log("Parametros: " + route.params.id);
});

onMounted(() => {
  store.state.isAbsolute = true;
  setNavPills();
  setTooltip();
});

onBeforeMount(() => {
  store.state.imageLayout = "profile-overview";
  store.state.showNavbar = false;
  store.state.showFooter = true;
  store.state.hideConfigButton = true;
  body.classList.add("profile-overview");

  service
    .getId(route.params.id)
    .then((response) => {
      console.log(response);

      titulo.value = response.data.data.titulo;
      fechaCreacion.value = response.data.data.fechaCreacion;
      descripcion.value = response.data.data.descripcion;
      cuerpo.value = response.data.data.cuerpo;
      //enlace.value = response.data.data.enlace;
      
      enlace.value = (props.module === "book") ? store.state.api_url +"/resources/"+response.data.data.archivo : response.data.data.enlace;
      
      const text = response.data.data.imagen;
      const myArray = text.split("/");
      imagen.value = myArray[myArray.length - 1];

      urlImage.value = store.state.api_url +"/resources/"+imagen.value;
    })
    .catch((error) => {
      console.log(error);
      //msg("Error", error.response.data.message, "error", 3000);
    });
});
onBeforeUnmount(() => {
  store.state.isAbsolute = false;
  store.state.imageLayout = "default";
  store.state.showNavbar = true;
  store.state.showFooter = true;
  store.state.hideConfigButton = false;
  body.classList.remove("profile-overview");
});
</script>
