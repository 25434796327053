<template>
  <div class="py-4 container-fluid">
    <v-row>
      <div class="grid">
        <div class="grid__content">
          <v-card
            color="#7496A7"
            variant="elevated"
            class="centro mx-auto text-white"
            max-width="400"
          >
            <v-card-item>
              <div>
                <div class="text-overline mb-1">CÓDIGO USUARIO</div>
                <div class="text-h6 mb-1">{{ codigo }}</div>
                <div class="text-caption"></div>
              </div>
            </v-card-item>

            <v-card-actions>
              <v-btn class="bg-orange" @click="descargarImagen()">
                Descargar
              </v-btn>
            </v-card-actions>
          </v-card>
          <hr />
          <div ref="codigo_qr">
            <v-card
              class="mx-auto text-white"
              color="#7496A7"
              max-width="400"
              prepend-icon="mdi-qrcode-scan"
              title="USA MI CÓDIGO"
            >
              <template v-slot:prepend>
                <v-icon size="x-large"></v-icon>
              </template>

              <v-card-text
                class="text-h5 py-2"
                style="
                  align-items: center;
                  text-align: center;
                  justify-content: center;
                "
              >
                <vue-qr :text="urlInvitado"></vue-qr>
              </v-card-text>

              <v-card-actions>
                <v-list-item class="w-100">
                  <template v-slot:prepend>
                    <v-avatar
                      color="grey-darken-3"
                      image="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                    ></v-avatar>
                  </template>

                  <v-list-item-title> {{ fullName }}</v-list-item-title>

                  <v-list-item-subtitle>AMIGO</v-list-item-subtitle>
                </v-list-item>
              </v-card-actions>
            </v-card>
          </div>
        </div>
      </div>
    </v-row>
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
//import { createWebHistory } from "vue-router";
import vueQr from "vue-qr/src/packages/vue-qr.vue";
import html2canvas from "html2canvas";

//import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

//const url = store.state.api_url;

let urlInvitado = ref("");
let codigo = ref("");
let fullName = ref("");
let codigo_qr = ref(null); //div

const descargarImagen = () => {
  console.log("Generando imagen . . .");
  generarImagen(codigo_qr.value)
    .then((imagen) => {
      const enlaceDescarga = document.createElement("a");
      enlaceDescarga.href = imagen;
      enlaceDescarga.download = "codigo.png";
      enlaceDescarga.click();
    })
    .catch((error) => {
      console.log(error);
    });
};

const generarImagen = (div) => {
  return html2canvas(div, { useCORS: true }).then((canvas) => {
    return canvas.toDataURL("image/png");
  });
};

onBeforeMount(() => {
  codigo.value = localStorage.getItem("codigo_usuario");
  fullName.value =
    localStorage.getItem("nombre") +
    " " +
    localStorage.getItem("apellido") +
    " " +
    localStorage.getItem("apellido_mat");

  urlInvitado.value = store.state.url + "/registro/" + codigo.value;
});
</script>

<style scoped>
</style>
